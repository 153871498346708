import React from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";

import type { SelectFieldProps } from "types";
import { autoComplete, fieldRules } from "utils/field.utils";

const GenericRadio: React.FunctionComponent<SelectFieldProps> = ({
  value,
  question,
  onChange,
  options,
  control,
}) => {
  const theme = useTheme();

  return (
    <Controller
      name={question.id}
      control={control}
      rules={fieldRules(question, useIntl())}
      render={({ field, fieldState }) => {
        autoComplete(options, field, onChange);
        return (
          <FormControl
            sx={{
              m: 0,
              width: "100%",
            }}
          >
            <RadioGroup
              {...field}
              value={value || ""}
              onChange={(e) => {
                let selectedOption = options.find(
                  (option) => option.name === e.target.value,
                );
                field.onChange(selectedOption);
                if (selectedOption) {
                  onChange({
                    id: selectedOption.id.toString(),
                    value: selectedOption.name,
                  });
                }
              }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.name}
                  control={<Radio onBlur={field.onBlur} />}
                  label={option.name}
                  style={{ margin: 0 }}
                />
              ))}
            </RadioGroup>
            <FormHelperText>
              <Typography variant="caption" color={theme.palette.error.main}>
                {fieldState.error?.message}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default GenericRadio;
