import React, { type FC, useEffect } from "react";
import { Control, FieldValues, type UseFormResetField } from "react-hook-form";

import { Box, Divider, Grid, Typography } from "@mui/material";

import { DealerLocator } from "./DealerLocator";
import { MandatoryAsterisk } from "./index";
import {
  GenericCheckbox,
  GenericMultiSelect,
  GenericNumberInput,
  GenericRadio,
  GenericSelect,
  GenericTextInput,
} from "components/question/inputs";
import { StyledTitle } from "components/quotation";
import Spinner from "components/spinner";
import { SubStepper } from "components/stepper";
import { useCallBackend, useIsMobile } from "hooks/index";
import DamageView from "poc-trade-in-monk";
import { removeFromState, setGenericState } from "store/funnel/funnel.reducer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  QUESTION_TYPE,
  type QuestionAnswer,
  type QuestionDTO,
  type QuestionState,
  type SectionDTO,
} from "types";
import { checkPropertiesOf, getLocaleFromUrl } from "utils";

interface Props {
  question: QuestionDTO;
  section?: QuestionDTO[];
  depth?: number;
  control: Control<FieldValues, string>;
  resetField: UseFormResetField<FieldValues>;
  onLoadingChange?: (isLoading: boolean) => void;
  handleNext?: () => void;
}

const QuestionField: FC<Props> = ({
  question,
  section,
  depth,
  control,
  resetField,
  onLoadingChange,
  handleNext,
}) => {
  const { funnelType, funnelProfile, funnelMethod, state, formInit } =
    useAppSelector((state) => state.funnel);

  const { config } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const answers = state?.find(
    (questionState) => questionState.id === question.id,
  )?.answers;

  const { getNextStep, isLoading } = useCallBackend(question);

  useEffect(() => {
    if (question.primary) {
      getNextStep();
    }
  }, [answers, funnelProfile, funnelMethod, funnelType]);

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(isLoading);
    }
  }, [isLoading, onLoadingChange]);

  const clearQuestionArray = formInit?.sections.reduce(
    (accumulator: string[], section: SectionDTO) => {
      const questions = section.questions;
      for (const q of questions) {
        if (q.requiredAnswers?.includes(question.id)) {
          accumulator.push(q.id);
        }
      }
      return accumulator;
    },
    [],
  );

  const onChange = (answers: QuestionAnswer[]) => {
    dispatch(setGenericState({ id: question.id, answers }));

    if (clearQuestionArray && clearQuestionArray.length > 0) {
      dispatch(removeFromState(clearQuestionArray));
      for (let question of clearQuestionArray) {
        resetField(question);
      }
    }
  };

  const onSubmit = (monkState: QuestionState[]) => {
    for (let question of monkState) {
      dispatch(setGenericState({ id: question.id, answers: question.answers }));
      handleNext && handleNext();
    }
  };

  const renderComponent = () => {
    switch (question.questionType) {
      case QUESTION_TYPE.INPUT_TEXT:
        return (
          <GenericTextInput
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([{ value }])}
            control={control}
          />
        );
      case QUESTION_TYPE.INPUT_NUMBER:
        return (
          <GenericNumberInput
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([{ value }])}
            control={control}
          />
        );
      case QUESTION_TYPE.SELECT:
        return (
          <GenericSelect
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([value])}
            options={question.options}
            control={control}
          />
        );
      case QUESTION_TYPE.RADIO:
        return (
          <GenericRadio
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([value])}
            options={question.options}
            control={control}
          />
        );
      case QUESTION_TYPE.MULTI_SELECT:
        return (
          <GenericMultiSelect
            question={question}
            {...(answers &&
              Array.isArray(answers) &&
              answers.every((item) =>
                checkPropertiesOf<{ value: string }>(["value"], item),
              ) && {
                value: (answers as Array<{ value: string }>).map(
                  (e) => e.value,
                ),
              })}
            onChange={(value) => onChange(value)}
            options={question.options}
            control={control}
          />
        );
      case QUESTION_TYPE.COLOR_SELECT:
        return (
          <GenericSelect
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([value])}
            options={question.options}
            color
            control={control}
          />
        );
      case QUESTION_TYPE.DEALER_LOCATOR:
        return (
          <DealerLocator
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([value])}
            options={question.options}
            control={control}
          />
        );
      case QUESTION_TYPE.CHECKBOX:
        return (
          <GenericCheckbox
            question={question}
            {...(answers &&
              "value" in answers[0] && { value: answers[0].value })}
            onChange={(value) => onChange([{ value }])}
            control={control}
          />
        );
      case QUESTION_TYPE.DAMAGES:
        if (!config) {
          return <Spinner fullHeight />;
        }

        return (
          funnelProfile &&
          funnelType &&
          funnelMethod && (
            <DamageView
              env="TEST"
              onSubmit={(answers: QuestionState[]) => {
                onSubmit(answers);
              }}
              locale={getLocaleFromUrl(config?.env)}
              profile={funnelProfile}
              funnelType={funnelType}
              funnelMethod={funnelMethod}
            />
          )
        );

        return;
      default:
        return <></>;
    }
  };

  const renderStepper = () => {
    if (question.subQuestions?.length) {
      return (
        <>
          <SubStepper
            subQuestions={question.subQuestions}
            control={control}
            resetField={resetField}
          />
          {section && section[section.indexOf(question) + 1] && <Divider />}
        </>
      );
    }
  };

  return (
    <>
      {!(
        (question.questionType === QUESTION_TYPE.MULTI_SELECT &&
          question.options.length === 0) ||
        (question.questionType === QUESTION_TYPE.RADIO &&
          question.options.length === 0)
      ) && (
        <Grid
          item
          container
          sm={
            depth ||
            question.questionType === QUESTION_TYPE.DEALER_LOCATOR ||
            question.questionType === QUESTION_TYPE.DAMAGES
              ? 12
              : 8
          }
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          mt={1}
          mb={2}
          gap={2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {!depth && (
              <>
                <Grid
                  item
                  md={
                    question.questionType === QUESTION_TYPE.DEALER_LOCATOR
                      ? 10
                      : 12
                  }
                  xs={12}
                >
                  <StyledTitle
                    children={
                      <Typography
                        variant={
                          isMobile && !question.subQuestions?.length
                            ? "body1"
                            : "h5"
                        }
                        fontWeight={700}
                        display="flex"
                      >
                        {question.label}
                        {question.mandatory && <MandatoryAsterisk />}
                      </Typography>
                    }
                    my={0}
                    ml={1}
                  />
                </Grid>
              </>
            )}
            {isLoading && !depth && <Spinner />}
          </Box>
          {renderComponent()}
          {renderStepper()}
        </Grid>
      )}
    </>
  );
};

export default QuestionField;
