import React, { type FC, useState } from "react";
import { Control, FieldValues, type UseFormResetField } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { MandatoryAsterisk, QuestionField } from "components/question";
import Spinner from "components/spinner";
import type { QuestionDTO } from "types";

interface Props {
  subQuestions: QuestionDTO[];
  control: Control<FieldValues, string>;
  resetField: UseFormResetField<FieldValues>;
}

const SubStepper: FC<Props> = ({ subQuestions, control, resetField }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLoadingChange = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  const steps = subQuestions.map((subQuestion) => ({
    label: subQuestion.label,
    content: (
      <QuestionField
        question={subQuestion}
        key={subQuestion.id}
        depth={1}
        control={control}
        resetField={resetField}
        onLoadingChange={handleLoadingChange}
      />
    ),
    mandatory: subQuestion.mandatory,
  }));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid
      container
      mb={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item lg={8} width="100%">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps?.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  display="flex"
                  alignItems="center"
                >
                  {step.label}
                  {step.mandatory && <MandatoryAsterisk />}
                  {loading && <Spinner />}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.content}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid xs={12} container spacing={2} justifyContent="center">
                    {index !== 0 && (
                      <Grid xs={6}>
                        <Button
                          onClick={handleBack}
                          sx={{ mt: 1, width: "100%" }}
                        >
                          <FormattedMessage id="funnel.stepper.back" />
                        </Button>
                      </Grid>
                    )}
                    <Grid xs={6}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, width: "100%" }}
                      >
                        <FormattedMessage
                          id={
                            index === steps.length - 1
                              ? "funnel.stepper.finish"
                              : "funnel.form.next"
                          }
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {activeStep === steps?.length && (
        <Paper square elevation={0}>
          <Grid
            container
            sx={{
              pt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>
              <FormattedMessage id="funnel.stepper.finished" />
            </Typography>
            <Button onClick={handleReset} sx={{ mt: 1, width: { xs: "100%" } }}>
              <FormattedMessage id="funnel.stepper.reset" />
            </Button>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default SubStepper;
