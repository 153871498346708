import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Button, Typography, styled, useTheme } from "@mui/material";

const FourZeroFour: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleBackHome = () => navigate("/");

  const FourZeroFourContainer = styled("main")`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(3)};
  `;

  return (
    <FourZeroFourContainer>
      <Typography variant="h1">404</Typography>
      <Typography variant="h6" sx={{ p: 2 }}>
        <FormattedMessage id="404.label" />
      </Typography>
      <Button variant="contained" onClick={handleBackHome}>
        <FormattedMessage id="404.button.home" />
      </Button>
    </FourZeroFourContainer>
  );
};

export default FourZeroFour;
