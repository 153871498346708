import React, { useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";

import type {
  OptionDTO,
  QuestionAnswer,
  QuestionDTO,
  SelectAnswer,
} from "types";
import { fieldRules } from "utils/field.utils";

type GenericMultiSelectProps = {
  question: QuestionDTO;
  value?: string[];
  onChange: (arg0: QuestionAnswer[]) => void;
  options: OptionDTO[];
  control: Control<FieldValues, string>;
};

const GenericMultiSelect: React.FunctionComponent<GenericMultiSelectProps> = ({
  value,
  question,
  onChange,
  options,
  control,
}) => {
  const [checkedValues, setCheckValues] = useState<SelectAnswer[]>([]);

  const theme = useTheme();

  const handleChange = (
    option: SelectAnswer,
    fieldOnChange: (value: SelectAnswer[]) => void,
  ) => {
    const newCheckedValues: SelectAnswer[] = checkedValues.some(
      (el) => el.id === option.id,
    )
      ? checkedValues.filter((el) => el.id !== option.id)
      : [...checkedValues, option];

    setCheckValues(newCheckedValues);
    onChange(newCheckedValues);
    fieldOnChange(newCheckedValues);
  };

  return (
    <Controller
      name={question.id}
      control={control}
      rules={fieldRules(question, useIntl())}
      render={({ field, fieldState }) => (
        <FormControl
          sx={{
            m: 0,
            width: "100%",
          }}
        >
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.name}
                control={
                  <Checkbox
                    onChange={() => {
                      handleChange(
                        { id: option.id, value: option.name },
                        field.onChange,
                      );
                    }}
                    onBlur={field.onBlur}
                    checked={value && value.some((el) => el === option.name)}
                  />
                }
                label={option.name}
                sx={{ m: 0 }}
              />
            ))}
          </FormGroup>
          <FormHelperText>
            <Typography variant="caption" color={theme.palette.error.main}>
              {fieldState.error?.message}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default GenericMultiSelect;
