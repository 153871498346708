import React, { type FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  Checkbox,
  FormControl,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";

import type { InputFieldProps } from "types";
import { fieldRules } from "utils/field.utils";

const GenericCheckbox: FC<InputFieldProps> = ({
  value,
  question,
  onChange,
  control,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (!value) {
      onChange("false");
    }
  }, [value, onChange]);

  return (
    <Controller
      name={question.id}
      control={control}
      rules={fieldRules(question, useIntl())}
      render={({ field, fieldState }) => (
        <FormControl sx={{ width: "100%" }}>
          <Checkbox
            onChange={(e) => {
              onChange(e.target.checked ? "true" : "false");
              field.onChange(e.target.checked ? "true" : "");
            }}
            checked={value === "true"}
          />
          <FormHelperText>
            <Typography variant="caption" color={theme.palette.error.main}>
              {fieldState.error?.message}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default GenericCheckbox;
