import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { colors } from "assets/styles";
import { fonts } from "assets/typography";
import { footerLinks } from "utils";

const MainFooterContainer = styled("div")`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
`;

const AppFooter: React.FunctionComponent = () => {
  const theme = useTheme();

  return (
    <MainFooterContainer>
      <Grid item display="flex" justifyContent="space-between">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: theme.palette.common.black }}
            />
          }
          spacing={1}
          fontSize={theme.spacing(1.5)}
          fontFamily={fonts.nouvelRLight}
          padding={theme.spacing(2, 4, 2, 4)}
        >
          {footerLinks.map((link) => (
            <Link
              key={link.ref}
              href={link.ref}
              underline="none"
              fontFamily={fonts.nouvelR}
            >
              <FormattedMessage id={link.formattedMessageId} />
            </Link>
          ))}
        </Stack>
        <Typography
          fontSize={theme.spacing(1.25)}
          fontFamily={fonts.nouvelRBold}
          padding={theme.spacing(2, 4, 2, 4)}
        >
          &copy;
          <FormattedMessage id="footer.copyright" />
        </Typography>
      </Grid>
      <Grid
        container
        item
        spacing={0.5}
        justifyContent="center"
        padding={theme.spacing(3, 2, 3, 2)}
        bgcolor={colors.gray}
      >
        <Grid item>
          <FormattedMessage id="footer.preventive.message" />
        </Grid>
        <Grid item>
          <Link
            href="https://www.ecologie.gouv.fr/loi-dorientation-des-mobilites"
            underline="none"
            fontFamily={fonts.nouvelRBold}
          >
            <FormattedMessage id="footer.link.orientation.of.mobility.law" />
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://www.renault.fr/etiquettes-energie-voiture.html"
            fontFamily={fonts.nouvelRBold}
          >
            <FormattedMessage id="footer.link.energy.consumption" />
          </Link>
        </Grid>
      </Grid>
    </MainFooterContainer>
  );
};

export default AppFooter;
