import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";

import Section from "components/section";
import useCallBackend from "hooks/useCallBackend";
import useIsMobile from "hooks/useIsMobile";
import {
  emptyQuotationResult,
  setActiveStep,
} from "store/funnel/funnel.reducer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { QUESTION_TYPE, type SectionDTO } from "types";
import { getQuestionById } from "utils";

const MainStepper = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { formInit, state, activeStep } = useAppSelector(
    (state) => state.funnel,
  );

  useEffect(() => {
    if (!state) {
      dispatch(setActiveStep(0));
    }
  }, []);

  const isMobile = useIsMobile();

  const {
    control,
    formState: { isValid },
    resetField,
  } = useForm({
    mode: "onBlur",
    defaultValues: state?.reduce((acc, current) => {
      if (
        getQuestionById(current.id, formInit)?.questionType ===
        QUESTION_TYPE.MULTI_SELECT
      ) {
        if (current.answers) {
          return {
            ...acc,
            [current.id]: current.answers.map((answer) => {
              if ("value" in answer) {
                return answer.value;
              } else {
                return;
              }
            }),
          };
        }
      } else if (current.answers && "value" in current.answers[0]) {
        return { ...acc, [current.id]: current.answers[0].value };
      }
      return acc;
    }, {}),
  });

  const firstQuestionOfNextSection =
    formInit?.sections[
      formInit.sections.indexOf(formInit.sections[activeStep]) + 1
    ]?.questions[0];

  const { getNextStep, isLoading } = useCallBackend(
    firstQuestionOfNextSection,
    true,
  );

  const handleNext = () => {
    if (firstQuestionOfNextSection) {
      getNextStep();
    }
    dispatch(setActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const steps = formInit?.sections.map((section: SectionDTO) => ({
    id: section.id,
    label: section.label,
    content: (
      <Section
        key={section.id}
        section={section}
        isLoading={isLoading}
        control={control}
        resetField={resetField}
        handleNext={handleNext}
      />
    ),
  }));

  const currentStepQuestions = steps
    ? formInit?.sections[steps.indexOf(steps[activeStep])].questions
    : [];

  const isStepOptional = !currentStepQuestions?.filter(
    (question) => question.mandatory,
  ).length;

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  const { getQuotation } = useCallBackend();

  const handleFinish = () => {
    dispatch(emptyQuotationResult());
    getQuotation();
    navigate(intl.formatMessage({ id: "routes.quotation" }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "vertical" : "horizontal"}
        sx={{ my: 5 }}
      >
        {steps?.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            minHeight: { sm: "50vh" },
          }}
        >
          {steps && steps[activeStep].content}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-evenly",
          }}
        >
          {activeStep !== 0 && (
            <Button variant="contained" onClick={handleBack} sx={{ mr: 1 }}>
              <FormattedMessage id="funnel.stepper.back" />
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              steps && activeStep === steps?.length - 1
                ? handleFinish()
                : handleNext();
            }}
            disabled={!isValid}
          >
            <FormattedMessage
              id={
                steps && activeStep === steps?.length - 1
                  ? "funnel.form.finish"
                  : isStepOptional
                    ? "funnel.form.skip"
                    : "funnel.form.next"
              }
            />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default MainStepper;
